import { Subject } from 'rxjs';
import io, { Socket } from 'socket.io-client';

import { SocketClientType } from '../../../shared/models/common-models';
import { SocketMsg } from '../../../shared/models/socket-events';
import { getSocketMsg } from '../../../shared/shared-socket-utils';

const SOCKET_URL = process.env.VUE_APP_SOCKET_URL as string;

export class SocketClientService {
  connected = new Subject<void>();
  disconnected = new Subject<{
    reason: Socket.DisconnectReason;
    retrying: boolean;
  }>();
  error = new Subject<unknown>();
  message = new Subject<SocketMsg<unknown>>();

  private _socket!: Socket;

  connect(sessionId: string, clientType: SocketClientType): Promise<void> {
    return new Promise((resolve, reject) => {
      const socket = io(SOCKET_URL, {
        query: { sessionId, clientType },
      });

      socket.on('connect_error', (error) => {
        reject(error);
      });

      socket.on('connect', () => {
        console.log('Successfully connected to socket');
        this.connected.next();
        resolve();
      });

      socket.on('disconnect', (reason) => {
        console.log(`Disconnected from socket, reason: ${reason}`);

        const retrying =
          reason !== 'io server disconnect' &&
          reason !== 'io client disconnect';

        this.disconnected.next({ reason, retrying });
      });

      socket.on('error', (err) => {
        this.error.next(err);
      });

      socket.onAny((eventName, payload) => {
        try {
          const socketMsg = getSocketMsg(eventName, payload);
          this.message.next(socketMsg);
        } catch (error) {
          console.error(
            `Failed to parse socket message: ${payload}, with error: `,
            error
          );
        }
      });

      // Connect to the server
      socket.connect();
      this._socket = socket;
    });
  }

  sendMessage(message: SocketMsg<unknown>): void {
    this._socket.emit(message.type, JSON.stringify(message.payload));
  }

  disconnect(): void {
    this._socket.disconnect();
  }
}

export default new SocketClientService();

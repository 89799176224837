import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onDrop: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onDrop && _ctx.onDrop(...args))),
    onDragover: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onDragOver && _ctx.onDragOver(...args))),
    onDragleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onDragLeave && _ctx.onDragLeave(...args))),
    class: _normalizeClass(["overlayed-popup", {
      hide: !_ctx.showOverlay,
      'good-files-dragged': _ctx.isDragging && _ctx.isDraggingFiles,
      'bad-files-dragged': _ctx.isDragging && !_ctx.isDraggingFiles,
    }])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 34 /* CLASS, HYDRATE_EVENTS */))
}
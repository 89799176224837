
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Options({
  emits: ['files-drop', 'files-drag-over', 'files-drag-leave'],
})
export default class OverlayedPopup extends Vue {
  @Prop(Boolean) show!: boolean;
  @Prop(Boolean) showOnFilesDrag!: boolean;
  @Prop(Boolean) allowFilesDrop!: boolean;
  @Prop() isValidFiles!: (files: FileList | undefined) => boolean;

  isDragging = false;
  isDraggingFiles = false;

  private subscription: Subscription = new Subscription();

  get showOverlay(): boolean {
    return this.show || (this.isDragging && this.showOnFilesDrag);
  }

  onDragOver(event: DragEvent): void {
    if (!this.allowFilesDrop) return;
    event.preventDefault();
    event.stopImmediatePropagation();

    this.isDragging = true;

    const types = event.dataTransfer?.types;

    if (types && types.length > 0 && types[0] === 'Files') {
      this.$emit('files-drag-over');
      this.isDraggingFiles = true;
    }
  }

  onDragLeave(event: DragEvent): void {
    if (!this.allowFilesDrop) return;
    event.preventDefault();
    event.stopImmediatePropagation();
    this.$emit('files-drag-leave', event.dataTransfer?.files);
    this.isDragging = false;
    this.isDraggingFiles = false;
  }

  onDrop(event: DragEvent): void {
    if (!this.allowFilesDrop) return;
    event.preventDefault();
    event.stopImmediatePropagation();
    this.$emit('files-drop', event.dataTransfer?.files);
    this.isDragging = false;
    this.isDraggingFiles = false;
  }

  mounted(): void {
    this.subscription.add(
      fromEvent(document, 'dragover')
        .pipe(filter(() => this.showOnFilesDrag))
        .subscribe(() => (this.isDragging = true))
    );
  }

  unmounted(): void {
    this.subscription.unsubscribe();
  }
}

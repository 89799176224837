import { SocketMsg } from './models/socket-events';

export function getSocketMsg(
  eventName: string,
  payloadStr: string,
): SocketMsg<unknown> {
  return { type: eventName, payload: JSON.parse(payloadStr) } as SocketMsg<
    unknown
  >;
}
